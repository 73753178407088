<template>
  <b-container>
    <h3 class="title" v-if="step === 1">Create Album</h3>
    <h3 class="title" v-else>Add Song</h3>
    <div class="d-flex justify-content-center m-2">
      <div class="album-container">
        <div class="row" v-if="step === 1">
          <div class="my-auto col-12 col-md-5 col-xl-4">
            <div class="m-4">
              <croppa v-model="albumCover"
                      accept=".jpeg,.png,.jpg"
                      :width="190"
                      :height="190"
                      :placeholder="placeholder"
                      placeholder-color="#068bcd"
                      :placeholder-font-size="12"
                      :prevent-white-space="true"
                      canvas-color="#fff"
                      :show-remove-button="true"
                      remove-button-color="red"
                      :remove-button-size="25"
                      :show-loading="true"
                      :loading-size="25"
                      @file-type-mismatch="onFileTypeMismatch"
                      @new-image-drawn="handleNewImage"
                      @image-remove="handleImageRemove">
              </croppa>
              <div class="buttons">
                <i class="fas fa-search-plus icon"
                  @click="albumCover.zoomIn()">
                </i>
                <i class="fas fa-search-minus icon"
                  @click="albumCover.zoomOut()">
                </i>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-7 col-xl-8">
            <div class="mr-4 ml-4 mb-4 mt-5">
              <label for="albumName">Album Name:</label>
              <b-form-input class="mr-2"
                            id="albumName"
                            type="text"
                            aria-describedby="album-name"
                            autocomplete="off"
                            v-model="albumName"
                            @input="$v.albumName.$touch()"
                            :state="!$v.albumName.$error">
              </b-form-input>
              <b-form-invalid-feedback id="album-name"
                                       v-if="$v.albumName.$error">
                <span v-if="!$v.albumName.required">
                  This is a required field.
                </span>
                <span v-if="!$v.albumName.uniqueAlbumName &&
                        $v.albumName.required">
                  Album name is already used.
                </span>
              </b-form-invalid-feedback>

              <div class="d-flex justify-content-end mt-3">
                <b-button variant="outline-success"
                          :disabled="$v.$invalid || isAlbumCoverEmpty"
                          @click="uploadAlbumCover">
                  Create Album
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="step === 2">
          <div class="col">
            <div class="m-4">
              <label for="audioFile">Song*:</label>
              <b-form-file id="audioFile"
                          accept="audio/*"
                          aria-describedby="audio-file"
                          placeholder="Choose a file..."
                          ref="audioFileInput"
                          v-model="audioFile"
                          @input="$v.audioFile.$touch()"
                          :state="!$v.audioFile.$error">
              </b-form-file>
              <b-form-invalid-feedback id="audio-file"
                                       v-if="$v.audioFile.$error">
                <span v-if="!$v.audioFile.validSongType">
                  Selected song type is not valid. Please select mp3 file.
                </span>
                <span v-if="$v.audioFile.validSongType &&
                        !$v.audioFile.uniqueSelectedSong">
                  Selected song is already added.
                </span>
              </b-form-invalid-feedback>
              <div class="mt-1 mb-2" v-if="audioFile && !$v.audioFile.$error">
                <b>Selected file:</b> {{audioFile && audioFile.name}}
              </div>
              <div v-if="uploading">
                <b-progress :max="max"
                            :value="progress"
                            variant="success"
                            show-progress animated>
                </b-progress>
              </div>
              <b-form-input class="mr-2 artist-name"
                            id="artistName"
                            type="text"
                            aria-describedby="artist-name"
                            autocomplete="off"
                            placeholder="Artist Name"
                            v-model="artist">
              </b-form-input>

              <b-button class="mt-3 mb-3 mr-3 ml-auto"
                        :disabled="$v.$invalid"
                        variant="outline-success"
                        @click="uploadSong">
                Upload
              </b-button>
              <b-button class="mt-3 mb-3 ml-auto"
                        variant="outline-primary"
                        @click="done">
                <span v-if="albumSongs">Done</span>
                <span v-else>Skip</span>
              </b-button>
              <SongsList :songs="albumSongs"></SongsList>

              <i>*You can upload mutiple songs one by one.</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.5"
             :width="70"
             :height="70"
    ></loading>
    <v-dialog/>
  </b-container>
</template>
<script>
  const Loading = () => import('vue-loading-overlay');
  const SongsList = () => import('./SongList.vue');
  import { mapGetters, mapActions } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import 'vue-croppa/dist/vue-croppa.css';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name     : 'CreateAlbum',
    computed : {
      ...mapGetters({
        albumSongs : 'media/albumSongs',
      }),
    },
    data() {
      return {
        isLoading          : false,
        fullPage           : true,
        step               : 1,
        audioFile          : null,
        albumCover         : null,
        uploading          : false,
        progress           : 0,
        albumName          : null,
        uploaded           : false,
        max                : 100,
        albumId            : null,
        musicId            : null,
        uniqueAlbumName    : true,
        uniqueSelectedSong : true,
        validSongType      : true,
        placeholder        : 'Album Cover',
        isAlbumCoverEmpty  : true,
        artist             : null,
      };
    },
    watch : {
      albumName(value) {
        this.uniqueAlbumName = true;
        if (value)
          this.checkAlbumName();
      },

      audioFile(value) {
        this.validSongType = true;
        if (value) {
          if (value.type === 'audio/mp3' || value.type === 'audio/mpeg')
            this.checkSongName();
          else
            this.validSongType = false;
        }
      },
    },
    methods : {
      ...mapActions({
        'getAlbumSongs'   : 'media/getAlbumSongs',
        'clearAlbumSongs' : 'media/clearAlbumSongs',
      }),

      /**
       * Check if Album Name is unique
       */
      // eslint-disable-next-line no-undef
      checkAlbumName : _.debounce(function() {
        this.$http.get('/mobile/api/album/albumname', {
          params : {
            albumName : this.albumName,
          },
        }).then(response => {
          this.uniqueAlbumName = (response.data) ? false : true;
        });
      }, 500),

      /**
       * Check if Song Name is unique
       */
      // eslint-disable-next-line no-undef
      checkSongName : _.debounce(function() {
        // eslint-disable-next-line no-undef
        const filename = _.split(this.audioFile.name, '.');
        this.$http.get('/mobile/api/album/songname', {
          params : {
            songName : filename[0],
            albumId  : this.albumId,
          },
        }).then(response => {
          this.uniqueSelectedSong = (response.data) ? false : true;
        });
      }, 500),


      /**
       * Upload Album Cover
       */
      async uploadAlbumCover() {
        this.albumCover.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('file', blob, 'image.jpg');
          formData.append('imageType', 'album_cover');
          formData.append('albumName', this.albumName);
          formData.append('action', 'Add');

          try {
            this.isLoading = true;
            const result = await
              this.$http.post('/api/upload/image/album/thumbnail', formData, {
                onUploadProgress : e =>
                  this.progress = Math.round(e.loaded * 100 / e.total),
              }
              );

            if (result) {
              this.isLoading = false;
              this.albumId = result.data.albumId;
              this.clearAlbumSongs();
              this.step = 2; // Go to next step, w/c is Add Song
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success!',
                text  : 'Created Album!',
              });
            }
          } catch (error) {
            this.isLoading = false;
            this.$notify({
              group : 'notif',
              type  : 'failed',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }
        }, 'image/jpeg');
      },

      /**
       * Close Modal
       */
      onModalClose() {

      },

      /**
       * Done uploading songs
       */
      done() {
        this.clearAlbumSongs();
        this.step = 1;
        this.albumId = null;
        this.musicId = null;
        this.albumName = null;
      },

      /**
       * Upload Song
       */
      async uploadSong() {
        if (this.audioFile) {
          const formData = new FormData();
          formData.append('file', this.audioFile);
          formData.append('albumName', this.albumName);
          formData.append('albumId', this.albumId);
          formData.append('notify', false);
          if(this.artist)
            formData.append('artist', this.artist);

          try {
            this.isLoading = true;
            this.uploading = true;
            const result = await this.$http.post('/api/add/mp3', formData, {
              onUploadProgress : e =>
                this.progress = Math.round(e.loaded * 100 / e.total),
            });

            if (result) {
              this.uploading = false;
              this.uploaded = true;
              this.progress = 0;
              this.artist = null;
              this.getAlbumSongs(this.albumId);
              const filename = _.split(this.audioFile.name, '.');
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success!',
                text  : 'Added ' + filename[0] + ' successfully!',
              });
              this.audioFile = null;
              this.$nextTick(() => {
                this.$v.$reset();
              });
              this.isLoading = false;
            }
          } catch (err) {
            this.isLoading = false;
            this.$notify({
              group : 'notif',
              type  : 'failed',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }
        }
      },

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch() {
        this.placeholder = 'Invalid file type.';
      },

      /**
       * Callback function after croppa image selection
       */
      handleNewImage() {
        this.isAlbumCoverEmpty = false;
      },

      /**
       * Callback function after croppa image removed
       */
      handleImageRemove() {
        this.isAlbumCoverEmpty = true;
      },
    },
    components : {
      SongsList,
      Loading,
    },
    validations() {
      if (this.step === 1) {
        return {
          albumName : {
            required,
            uniqueAlbumName() {
              return this.uniqueAlbumName;
            },
          },
          albumCover : { required },
        }
      }
      if (this.step === 2) {
        return {
          audioFile : {
            required,
            uniqueSelectedSong() {
              return this.uniqueSelectedSong;
            },
            validSongType() {
              return this.validSongType;
            },
          },
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/mobile_music/create-album";
  .artist-name {
    margin: 10px 0 0;
  }
</style>
